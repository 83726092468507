// GlobalState.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// Create a context for the global state
const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
  const [country, setCountry] = useState('default');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCountry() {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userCountry = response.data.country;
        console.log(response)
        setCountry(userCountry);
      } catch (error) {
        console.error('Error fetching user country:', error);
        setCountry('default');
      } finally {
        setLoading(false);
      }
    }

    fetchCountry();
  }, []);

  return (
    <GlobalStateContext.Provider value={{ country, loading }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Create a custom hook to use the global state
export const useGlobalState = () => useContext(GlobalStateContext);
