import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { db } from '../Firebase';
import { getDoc, updateDoc, doc } from 'firebase/firestore';

const Footer = ({ msgPrivacy, msgTOS, country }) => {

  const [contactNum, setContactNum] = useState('');

  useEffect(()=>{
    switch(country){
      case 'US':
        setContactNum('(281) 706 2106');
        break;
      case 'IE':
        setContactNum('(01) 575 5310');
        break;
      case 'CA':
        setContactNum('(416) 800 2724');
        break;
    }
  }, [country])

    const [diocese, setDiocese] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();

      console.log(diocese, email);
    
      try {
        const docRef = doc(db, 'mailing-list', 'list');
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          /*const currentList = docSnap.data() || [];
          const updatedList = [...currentList, email];
    
          await updateDoc(docRef, {
            emails: updatedList,
          });
    
          alert('Subscribed successfully');*/
          alert('An error has occured in subscribing');
        } else {
          alert('An error has occured in subscribing');
        }
      } catch (error) {
        alert('An error has occured in subscribing');
      }
    };
    
    

    return(
        <>
            <Marquee style={{width: '100vw', height: '25vh', opacity: '0.01', color: '#ffffff', pointerEvents: 'none', zIndex: '1'}}>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
                <h2 className='marquee-text'>EVONPOLISHING</h2>
            </Marquee>
            <div className='footer'>
                <div>
                    <h5>Want to know when we'll be in your diocese?</h5>
                    <p>Subscribe to our mailing list to be sure you won't miss us on one of our trips!</p>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <input type='text' name='diocese' placeholder='Diocese' value={diocese} onChange={(e) => setDiocese(e.target.value)}/>
                        <input type='text' name='email' placeholder='Email Address' id='email-footer' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <input type='submit' value='>'/>
                    </form>
                </div>
                <div>
                    <h4>CONTACT</h4>
                    <p>contact@evonpolishing.com</p>
                    <p>{contactNum}</p>
                </div>
                <div>
                    <h4>LEGAL</h4>
                    <p>Evon Polishing ©2023</p>
                    <p onClick={() => msgPrivacy()}>Privacy Policy</p>
                    <p onClick={() => msgTOS()}>Terms of Service</p>
                </div>
            </div>
        </>
    );
}

export default Footer;