import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = ({ showAlert, country }) => {

  const [contactNum, setContactNum] = useState('');

  useEffect(()=>{
    switch(country){
      case 'US':
        setContactNum('(281) 706 2106');
        break;
      case 'IE':
        setContactNum('(01) 575 5310');
        break;
      case 'CA':
        setContactNum('(416) 800 2724');
        break;
    }
  }, [country])

  const [formData, setFormData] = useState({
    name: '',
    parishName: '',
    province: '',
    diocese: '',
    phoneNumber: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      parishName: formData.parishName,
      province: formData.province,
      diocese: formData.diocese,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      message: formData.message,
    };

    try {

      const resp = await emailjs.send('service_x9e4l7c', 'template_8gb7so2', templateParams, '2gnPvmEIF4b9xOZCP');
      console.log(resp);
      setFormData({
        name: '',
        parishName: '',
        province: '',
        diocese: '',
        phoneNumber: '',
        email: '',
        message: '',
      });

      alert('Email Successfully Sent');
    } catch (error) {
      alert('Email Failed to Send');
    }
  };

  return (
    <div className='contact' id='contact-scroll'>
      <div className='side'>
        <h4>Let's Work Together</h4>
        <p>contact@evonpolishing.com</p>
        <p>{contactNum}</p>
      </div>
      <div className='side'>
        <form onSubmit={handleSubmit}>
          <input type='text' placeholder='Your Name' name='name' value={formData.name} onChange={handleChange} />
          <input type='text' placeholder='Name of Parish' name='parishName' value={formData.parishName} onChange={handleChange} />
          <input type='text' placeholder='Diocese' name='diocese' value={formData.diocese} onChange={handleChange}/>
          <input type='text' placeholder='Phone Number' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} />
          <input type='text' placeholder='Email Address' name='email' value={formData.email} onChange={handleChange} />
          <textarea placeholder='How Can We Help?' name='message' value={formData.message} onChange={handleChange}></textarea>
          <p id='form-text'>
            By submitting this form, you agree to share the above entered information with Evon Polishing
            and consent to being contacted by a representative regarding restoration work in your church.
          </p>
          <input type='submit' value='SUBMIT' />
        </form>
      </div>
    </div>
  );
}

export default Contact;
