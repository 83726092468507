const Watermark = () => {
    return(
            <a href='https://eclipsepixel.com/' style={{
                color: '#FFFFFF',
                fontSize: '12px',
                textDecoration: 'none',
                position: 'fixed',
                right: '0',
                bottom: '0',
                padding: '6px',
                backgroundColor: '#000000',
                cursor: 'pointer'
            }}>Powered by EclipsePixel</a>
    );
}

export default Watermark;