const Services = () => {
    const sc1 = process.env.PUBLIC_URL + '/sc1.png';
    const sc2 = process.env.PUBLIC_URL + '/sc2.png';
    const sc3 = process.env.PUBLIC_URL + '/sc3.png';
    const sc4 = process.env.PUBLIC_URL + '/sc4.png';
    const sc5 = process.env.PUBLIC_URL + '/sc5.png';
    const sc6 = process.env.PUBLIC_URL + '/sc6.png';
    return(
        <div className='services' id='services-scroll'>
            <div className='tile' data-aos="fade-up">
                <div className='image icon' style={{backgroundImage: `url(${sc1})`}}/>
                <h1>Sacred Vessels</h1>
                <p>Have your sacred vessels restored to their original glory.</p>
            </div>
            <div className='tile' data-aos="fade-up">
                <div className='image icon' style={{backgroundImage: `url(${sc2})`}}/>
                <h1>Brass Polishing</h1>
                <p>Polishing and protection for all of your brassware.</p>
            </div>
            <div className='tile' data-aos="fade-up">
                <div className='image icon' style={{backgroundImage: `url(${sc3})`}}/>
                <h1>Gold & Silver Plating</h1>
                <p>We provide electroplating services in 24kt Gold and Sterling Silver.</p>
            </div>
            <div className='tile' data-aos="fade-up">
                <div className='image icon' style={{backgroundImage: `url(${sc4})`}}/>
                <h1>Kneeler Upholstery</h1>
                <p>Your congregation will thank you for the re-padded and freshly upholstered kneelers.</p>
            </div>
            <div className='tile' data-aos="fade-up">
                <div className='image icon' style={{backgroundImage: `url(${sc5})`}}/>
                <h1>Repairs</h1>
                <p>If any of your items are being restored, we do our best to make the necessary repairs.</p>
            </div>
        </div>
    );
}

export default Services;