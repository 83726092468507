const Notif = ({ message, clearMsg }) => {

    const messages = () => {
        switch(message){
            case 'privacypolicy':
                return(
                    <div>
                    <h1>PRIVACY POLICY</h1>
                    <p>Thank you for using our website. This Privacy Policy outlines how we collect, use, and protect the information you provide while using our website and services. By accessing or using our website, you consent to the practices described in this policy.</p>
                    <h2>1. Information We Collect:</h2>
                    <p>We collect the following personal information through the forms on our website:</p>
                    <ul>
                        <li>Name</li>
                        <li>Name of Parish</li>
                        <li>Diocese</li>
                        <li>Phone Number</li>
                        <li>Email Address</li>
                    </ul>
                    <h2>2. How We Use Your Information:</h2>
                    <p>We use the collected information for the following purposes:</p>
                    <ul>
                        <li>Responding to your inquiries or requests</li>
                        <li>Providing information about our products or services</li>
                        <li>Internal record keeping</li>
                        <li>Complying with legal obligations</li>
                    </ul>
                    <h2>3. Information Sharing:</h2>
                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your explicit consent. However, we may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you, provided that those parties agree to keep this information confidential.</p>
                    <p>We may also disclose your information when we believe it is appropriate to comply with the law, enforce our site policies, or protect our or others' rights, property, or safety.</p>
                    <h2>4. Cookies and Tracking:</h2>
                    <p>Our website may use cookies to enhance your experience. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enable the site's or service provider's systems to recognize your browser and capture and remember certain information. You can choose to disable cookies through your browser settings.</p>
                    <h2>5. Security:</h2>
                    <p>We are committed to ensuring the security of your information. We implement a variety of security measures to maintain the safety of your personal information.</p>
                    <h2>6. Your Choices:</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Review and update your personal information</li>
                        <li>Opt-out of receiving promotional emails</li>
                        <li>Request the deletion of your personal information (subject to legal obligations)</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us using the information provided at the end of this Privacy Policy.</p>
                    <h2>7. Changes to This Privacy Policy:</h2>
                    <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Please review this page periodically for changes.</p>
                    <h2>8. Contact Us:</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@evonpolishing.com.</p>
                    </div>
                );
            case 'termsofservice':
                return(
                    <div>
                    <h1>TERMS OF SERVICE</h1>
                    <h2>1. Acceptance of Terms:</h2>
                    <p>By clicking the submit button, users acknowledge and agree to the following terms.</p>
                    <h2>2. User Responsibilities:</h2>
                    <p>Users are only responsible for upholding appointments.</p>
                    <h2>3. Intellectual Property:</h2>
                    <p>This service involves antique refurbishment; no intellectual property is associated.</p>
                    <h2>4. Payment and Billing:</h2>
                    <ul>
                        <li>Invoices will be sent electronically or included in the delivery.</li>
                        <li>Payment should be made within 2 weeks of invoicing.</li>
                        <li>Checks sent by mail must be sent before the end of the 2 weeks.</li>
                        <li>Delays in the mail after the 2 weeks are acceptable.</li>
                    </ul>
                    <h2>5. Damages or Losses:</h2>
                    <p>Damages or losses will be handled through repairs and replacements.</p>
                    <h2>6. Dispute Resolution:</h2>
                    <p>Disputes are to be handled directly between clients and representatives of our company.</p>
                    <h2>7. Changes to Terms:</h2>
                    <ul>
                        <li>Users will be sent electronic notices (email or text) of updates to the Terms of Service.</li>
                        <li>Active projects will not be affected by these changes.</li>
                    </ul>
                    <h2>8. Communication:</h2>
                    <p>Communications will be conducted through phone, text, and email.</p>
                    </div>
                );
        }
    }

    if(message !== null){
        return(
            <div className='overlay'>
                <div className='overlay-content'>
                    <button onClick={() => clearMsg()}>Close</button>
                    {messages()}
                </div>
            </div>
        );
    }
    else{
        return(
            <></>
        );
    }
    
}

export default Notif;