import { useEffect, useState } from "react";

const Nav = () => {
    const [about, setAbout] = useState(null);
    const [services, setServices] = useState(null);
    const [contact, setContact] = useState(null);
    
    useEffect(() => {
        setAbout(document.getElementById('about-scroll'));
        setServices(document.getElementById('services-scroll'));
        setContact(document.getElementById('contact-scroll'));
    }, [])

    const scrollElement = (e) => {
        console.log(e)
        e.scrollIntoView({
            behavior: 'smooth', 
            block: 'start', 
        })
    }

    return(
        <div className='nav'>
            <h1>EVON POLISHING</h1>
            <div className='menu'>
                <button onClick={() => scrollElement(about)}>About</button>
                <button onClick={() => scrollElement(services)}>Services</button>
                <button onClick={() => scrollElement(contact)}>Contact</button>
            </div>
        </div>
    );
}

export default Nav;