// App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Nav from './components/Nav';
import Services from './components/Services';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Notif from './components/Notification';
import Watermark from './components/Watermark';
import { useGlobalState } from './Context';

function App() {
  const [msg, setMsg] = useState(null)
  const { country, loading } = useGlobalState();


  useEffect(() => {
    AOS.init({
      easing: 'ease-in-out'
    });
  }, []);

  if(loading){
    return(
    <div className='App'>
      <h1>LOADING</h1>
    </div>
  )
  }
  else{
    return (
      <div className="App">
        <Nav />
        <Hero country={country}/>
        <Services/>
        <Contact country={country}/>
        <Footer country={country} msgPrivacy={() => setMsg('privacypolicy')} msgTOS={() => setMsg('termsofservice')} />
        <Notif message={msg} clearMsg={() => setMsg(null)}/>
        <Watermark/>
      </div>
    );
  }
}

export default App;
