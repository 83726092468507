import { useEffect, useState } from "react";

const Hero = ({ country }) => {
    const [imgRef, setImgRef] = useState('');
    const backgroundImageUrl = process.env.PUBLIC_URL + 'hero.jpg';
    const canadaImageUrl = process.env.PUBLIC_URL + imgRef;

    useEffect(() => {
        switch(country){
            case 'US':
                setImgRef('/map-us.png');
                break;
            case 'IE':
                setImgRef('/map-ie.png');
                break;
            case 'CA':
                setImgRef('/map-ca.png');
                break;
        }
        console.log(country);
    }, [country])

    return(
        <div className='hero' id='about-scroll'>
            <div className='flex' id='flex-top'>
                <div className='text-block' id='hero-text'>
                    <p>Welcome to Evon Polishing, your dedicated partner in the meticulous restoration of ecclesiastical brassware and sacred vessels. With a legacy spanning over 20 years, we take pride in our sevice to Catholic Churches. Our team of skilled artisans passionately blends tradition and innovation to rejuvenate your cherished artifacts. Recognizing the profound significance of these sacred pieces, we are committed to ensuring they exude timeless splendor. Discover our services, and let us assist your Catholic congregation in reclaiming the beauty and historical value of your ecclesiastical treasures. Your faith, our craftsmanship—preserving the sacred heritage together.</p>
                    <button onClick={() => document.getElementById('contact-scroll').scrollIntoView({behavior: 'smooth', block: 'start'})}>Contact Us</button>
                </div>
                <div className='image' id='hero-img' style={{backgroundImage: `url(${backgroundImageUrl})`}}/>
            </div>
            <div className='flex' id='flex-bottom'>
                <div className='image' id='map' style={{backgroundImage: `url(${canadaImageUrl})`}}/>
                <div className='text-block' id='map-text'>
                    <h3>PROVIDING OUR SERVICES TO YOU</h3>
                    <p>
Evon Polishing is committed to providing dedicated service to Catholic churches nationwide. Our commitment to restoring the radiance of your ecclesiastical brassware and sacred vessels extends from coast to coast. We recognize the importance of preserving these cherished artifacts, and our aim is to make our services available to churches throughout the entire country. Whether your church is located in a bustling urban center or a more remote area, we are here to assist in rejuvenating the beauty and historical significance of your sacred items.</p>
                </div>
            </div>
        </div>
    );
}

export default Hero;